import { useState } from 'react'

import { Text, Button, Input, Flex, Box, Slider } from '@pancakeswap/uikit'

import { escapeRegExp } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import { useTheme } from 'styled-components'

// import useDebouncedChangeHandler from '../../../hooks/useDebouncedChangeHandler'
import QuestionHelper from '../../QuestionHelper'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

const SlippageTabs = () => {
  const theme = useTheme()
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState(0)

  const { t } = useTranslation()

  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === null || (ttl / 60).toString() === deadlineInput.toString()

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== null && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  // const parseCustomDeadline = (value: string) => {
  //   setDeadlineInput(value)

  //   try {
  //     const valueAsInt: number = Number.parseInt(value) * 60
  //     if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
  //       setTtl(valueAsInt)
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mb="24px">
        <Flex mb="12px">
          <Text fontSize="12px" color={theme.colors.softTextColor}>
            {t('Slippage Tolerance')}
          </Text>
          <QuestionHelper
            text={t(
              'Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.',
            )}
            placement="top-start"
            ml="4px"
            size="16px"
            color="primary"
          />
        </Flex>
        <Flex flexWrap="wrap">
          <Button
            mt="4px"
            mr="4px"
            scale="sm"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(10)
            }}
            style={{
              fontSize: '10px',
              color: userSlippageTolerance === 10 ? 'white' : theme.colors.softTextColor,
              backgroundColor:
                userSlippageTolerance === 10 ? theme.colors.upperMenuActive : theme.colors.inputPanelBackground,
              boxShadow: 'none',
            }}
          >
            0.1%
          </Button>
          <Button
            mt="4px"
            mr="4px"
            scale="sm"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(50)
            }}
            style={{
              fontSize: '10px',
              color: userSlippageTolerance === 50 ? 'white' : theme.colors.softTextColor,
              backgroundColor:
                userSlippageTolerance === 50 ? theme.colors.upperMenuActive : theme.colors.inputPanelBackground,
              boxShadow: 'none',
            }}
          >
            0.5%
          </Button>
          <Button
            mr="4px"
            mt="4px"
            scale="sm"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(100)
            }}
            style={{
              fontSize: '10px',
              color: userSlippageTolerance === 100 ? 'white' : theme.colors.softTextColor,
              backgroundColor:
                userSlippageTolerance === 100 ? theme.colors.upperMenuActive : theme.colors.inputPanelBackground,
              boxShadow: 'none',
            }}
          >
            1.0%
          </Button>
          <Flex alignItems="center">
            <Box width="76px" mt="4px">
              <Input
                scale="sm"
                inputMode="decimal"
                pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                placeholder={(userSlippageTolerance / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
                }}
                onChange={(event) => {
                  if (event.currentTarget.validity.valid) {
                    parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                  }
                }}
                isWarning={!slippageInputIsValid}
                // isSuccess={![10, 50, 100].includes(userSlippageTolerance)}
                style={{
                  borderRadius: '10px',
                  color: theme.colors.hardTextColor,
                  backgroundColor: theme.colors.inputPanelBackground,
                }}
              />
            </Box>
            <Text color="primary" bold ml="2px">
              %
            </Text>
          </Flex>
        </Flex>
        {!!slippageError && (
          <Text fontSize="14px" color={slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'} mt="8px">
            {slippageError === SlippageError.InvalidInput
              ? t('Enter a valid slippage percentage')
              : slippageError === SlippageError.RiskyLow
              ? t('Your transaction may fail')
              : t('Your transaction may be frontrun')}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="flex-start" alignItems="center" mb="24px">
        <Flex alignItems="center">
          <Text fontSize="12px">{t('Tx deadline (mins)')}</Text>
          <QuestionHelper
            text={t('Your transaction will revert if it is left confirming for longer than this time.')}
            placement="top-start"
            ml="4px"
            size="14px"
          />
        </Flex>
        <Flex>
          <Box width="52px" mt="4px">
            {/* <Input
              scale="sm"
              inputMode="numeric"
              pattern="^[0-9]+$"
              color={deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((ttl / 60).toString())
              }}
              placeholder={(ttl / 60).toString()}
              value={deadlineInput}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseCustomDeadline(event.target.value)
                }
              }}
            /> */}
            <Slider
              name="lp-amount"
              min={0}
              max={40}
              value={deadlineInput}
              onValueChanged={(value) => {
                setDeadlineInput(Math.ceil(value))
              }}
              mb="8px"
              valueLabel={`${deadlineInput}`}
              tooltip
              style={{ width: '160px' }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SlippageTabs
