function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
export var baseColors = {
    failure: "#ED4B9E",
    primary: "#194BFB",
    primaryBright: "#53DEE9",
    primaryDark: "#0098A1",
    secondary: "#1A202C",
    success: "#22C55E",
    warning: "#EC6998",
    danger: "#FF8282"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _objectSpread({}, baseColors, additionalColors, {
    htmlBackground: "#f6f7f9",
    topMenuBackground: "#FFFFFF",
    background: "#FAF9FA",
    backgroundDisabled: "#E9EAEB",
    backgroundAlt: "linear-gradient(111.49deg, rgba(255, 255, 255, 0.02) -8.95%, rgba(255, 255, 255, 0.0005) 114%)",
    backgroundAlt2: "linear-gradient(111.49deg, rgba(255, 255, 255, 0.02) -8.95%, rgba(255, 255, 255, 0.0005) 114%)",
    cardBorder: "#FAF9FA",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "transparent",
    invertedContrast: "#FFFFFF",
    input: "#F3F3FF",
    inputSecondary: "#d7caec",
    tertiary: "#FFFFFF",
    text: "#718096",
    textDisabled: "#DDDDDD",
    textSubtle: "#718096",
    disabled: "#E9EAEB",
    hrColor: "#F2F3F3",
    userMenuText: "#1A202C",
    userMenuIconBackground: "#F6F7F9",
    userMenuSubText: "#B4BAC7",
    upperMenuText: "#718096",
    upperMenuActive: "#194BFB",
    navButtonColor: "#194BFB",
    hardTextColor: "#121315",
    softTextColor: "#8791A5",
    maxButtonBackground: "#B4BAC7",
    inputPanelBackground: "#F5F6F8",
    iconColor: "#6D7892",
    toastBackground: "#FFFFFF",
    cardBackground: "#FFFFFF",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _objectSpread({}, baseColors, additionalColors, {
    htmlBackground: "#0B1636",
    topMenuBackground: "linear-gradient(180deg, #1E2947 0%, #1C2642 100%)",
    secondary: "#9A6AFF",
    background: "#0B1636",
    backgroundDisabled: "#3c3742",
    backgroundAlt: "#27262c",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "none",
    contrast: "#FFFFFF",
    dropdown: "#1E1D20",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "#372F47",
    inputSecondary: "#262130",
    primaryDark: "#0098A1",
    tertiary: "#1E2947",
    text: "#F4EEFF",
    textDisabled: "#444444",
    textSubtle: "#8791A5",
    disabled: "#524B63",
    hrColor: "#3B496A",
    userMenuText: "#FFFFFF",
    userMenuIconBackground: "#3B496A",
    userMenuSubText: "#B4BAC7",
    upperMenuText: "#718096",
    upperMenuActive: "#194BFB",
    navButtonColor: "#FFFFFF",
    hardTextColor: "#FFFFFF",
    softTextColor: "#979FB1",
    maxButtonBackground: "#334467",
    inputPanelBackground: "#2D3B5B",
    iconColor: "#6D7892",
    toastBackground: "#1C2D50",
    cardBackground: "linear-gradient(156.17deg, #1E2947 1.97%, #1C2642 56.75%)",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
        cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
