import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  ProductsIcon,
  ProductsFill,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  Liquidity,
  LiquidityFill,
  InfoChartIcon,
  InfoChartFill,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  FarmsIcon,
  PoolsIcon,
  LaunchpadIcon,
  InfoIconIcon,
  InfoIconFillIcon,
  LotteryIcon,
  LotteryFillIcon,
  ExchangeIcon,
  ExchangeFillIcon,
  NewLiquidityIcon,
  TradeFilledIcon,
  TradeIcon,
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('Trade'),
    icon: TradeIcon,
    fillIcon: TradeFilledIcon,
    href: '/swap',
    showItemsOnMobile: true,
    items: [
      {
        label: 'Exchange',
        subtext: 'Trade with the lowest fees',
        icon: ExchangeIcon,
        href: '/swap',
      },
      {
        label: 'Liquidity',
        subtext: 'Start earning from fees',
        icon: NewLiquidityIcon,
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Perpetual'),
    href: 'https://trade.swych.finance',
    icon: InfoChartIcon,
    fillIcon: InfoChartFill,
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Earn'),
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    href: '/launchpad',
    showItemsOnMobile: true,
    items: [
      {
        label: 'Farms (Soon)',
        subtext: 'Earn fixed auto-compounding rewards',
        icon: FarmsIcon,
        disabled: true,
        href: '/farms',
      },
      {
        label: 'Stake',
        subtext: 'Stake and Earn',
        icon: PoolsIcon,
        href: '/staking',
      },
      {
        label: 'Launchpad',
        subtext: 'Buy new projects launching on Swych',
        icon: LaunchpadIcon,
        href: '/launchpad',
      },
    ],
  },
  {
    label: t('SuperLotto'),
    href: '/superlotto',
    icon: LotteryIcon,
    fillIcon: LotteryFillIcon,
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Analytics'),
    href: '/info',
    icon: InfoIconIcon,
    fillIcon: InfoIconFillIcon,
    showItemsOnMobile: false,
    items: [],
  },
  // {
  //   label: t('Launchpad'),
  //   href: '/launchpad',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: 'Info',
  //   href: '/info',
  //   icon: InfoChartIcon,
  //   fillIcon: InfoChartFill,
  //   // hideSubNav: true,
  //   showItemsOnMobile: false,
  //   items: [],
  // },
]

export default config
