function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  bottom: 0;\n  position: absolute;\n  left: 14px;\n  width: calc(100% - 30px);\n  z-index: -1;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  position: relative;\n  width: 32px;\n  height: 16px;\n  border-radius: 10px;\n  background-color: #fff;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n  bottom: 0;\n  font-size: 12px;\n  left: ",
        ";\n  top: -47px;\n  position: absolute;\n  text-align: center;\n  min-width: 32px; // Slider thumb size\n  height: 19px;\n  border-radius: 10px;\n  background-color: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n  position: absolute;\n  left: 4px;\n  width: calc(100% - 8px);\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n  cursor: ",
        ";\n  height: 32px;\n  position: relative;\n  ::-webkit-slider-thumb {\n    ",
        "\n  }\n  ::-moz-range-thumb {\n    ",
        "\n  }\n  ::-ms-thumb {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  background-color: ",
        ";\n  height: 12px;\n  position: absolute;\n  top: 12px;\n  width: 100%;\n  border-radius: 12px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n  background-color: ",
        ";\n  filter: ",
        ";\n  height: 12px;\n  position: absolute;\n  top: 12px;\n  border-radius: 12px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Text from "../Text/Text";
var getCursorStyle = function(param) {
    var _disabled = param.disabled, disabled = _disabled === void 0 ? false : _disabled;
    return disabled ? "not-allowed" : "cursor";
};
var circle = "\"data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8.22461' r='8' fill='%23194BFB'/%3E%3C/svg%3E%0A\"";
var getBaseThumbStyles = function(param) {
    var disabled = param.disabled;
    return "\n  -webkit-appearance: none;\n  background-image: url(".concat(circle, ");\n  background-color: transparent;\n  box-shadow: none;\n  border: 0;\n  cursor: ").concat(getCursorStyle, ";\n  width: 16px;\n  height: 16px;\n  filter: ").concat(disabled ? "grayscale(100%)" : "none", ";\n  transform: translate(-2px, 0px);\n  transition: 200ms transform;\n");
};
export var SliderLabelContainer = styled.div.withConfig({
    componentId: "sc-8c1ec5ef-0"
})(_templateObject());
export var Tooltip = styled.div.withConfig({
    componentId: "sc-8c1ec5ef-1"
})(_templateObject1());
export var SliderLabel = styled(Text).withConfig({
    componentId: "sc-8c1ec5ef-2"
})(_templateObject2(), function(param) {
    var progress = param.progress;
    return progress;
}, function(param) {
    var theme = param.theme;
    return theme.colors.input;
});
export var BunnySlider = styled.div.withConfig({
    componentId: "sc-8c1ec5ef-3"
})(_templateObject3());
export var StyledInput = styled.input.withConfig({
    componentId: "sc-8c1ec5ef-4"
})(_templateObject4(), getCursorStyle, getBaseThumbStyles, getBaseThumbStyles, getBaseThumbStyles);
export var BarBackground = styled.div.withConfig({
    componentId: "sc-8c1ec5ef-5"
})(_templateObject5(), function(param) {
    var theme = param.theme;
    return theme.colors.hrColor;
});
export var BarProgress = styled.div.withConfig({
    componentId: "sc-8c1ec5ef-6"
})(_templateObject6(), function(param) {
    var theme = param.theme;
    return theme.colors.primary;
}, function(param) {
    var disabled = param.disabled;
    return disabled ? "grayscale(100%)" : "none";
});
