import { BlockIcon, CheckmarkCircleIcon, Flex, Link, OpenNewIcon, RefreshIcon, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { TransactionDetails } from 'state/transactions/reducer'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBscScanLink } from 'utils'

interface TransactionRowProps {
  txn: TransactionDetails
}

const TxnIcon = styled(Flex)`
  align-items: center;
  flex: none;
  width: 24px;
`

const Summary = styled.div`
  flex: 1;
`

const TxnLink = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`

const renderIcon = (txn: TransactionDetails) => {
  if (!txn.receipt) {
    return <RefreshIcon spin width="24px" />
  }

  return txn.receipt?.status === 1 || typeof txn.receipt?.status === 'undefined' ? (
    <CheckmarkCircleIcon color="primary" width="24px" />
  ) : (
    <BlockIcon color="failure" width="24px" />
  )
}

const TransactionRow: React.FC<TransactionRowProps> = ({ txn }) => {
  const { chainId } = useActiveWeb3React()

  if (!txn) {
    return null
  }

  return (
    <TxnLink href={getBscScanLink(txn.hash, 'transaction', chainId)} external style={{ position: 'relative' }}>
      <Text color="primary" style={{ fontSize: 12 }}>
        {txn.summary ?? txn.hash}
      </Text>
      <div style={{ position: 'absolute', right: 0, marginLeft: 12 }}>
        <TxnIcon style={{ display: 'inline', marginLeft: 4 }}>
          <OpenNewIcon width="24px" color="primary" />
        </TxnIcon>
        <TxnIcon style={{ display: 'inline', marginLeft: 4 }}>{renderIcon(txn)}</TxnIcon>
      </div>
    </TxnLink>
  )
}

export default TransactionRow
