function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  border-color: ",
        ";\n  border-style: solid;\n  border-width: 1px 0 0;\n  margin: 4px 0;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  align-items: center;\n  border: 0;\n  background: transparent;\n  color: ",
        ";\n  cursor: ",
        ";\n  display: flex;\n  font-size: 12px;\n  font-weight: 700;\n  height: fit-content;\n  justify-content: space-between;\n  outline: 0;\n  padding: 8px 25px;\n  width: 100%;\n\n  &:hover:not(:disabled) {\n    background-color: ",
        ";\n  }\n\n  &:active:not(:disabled) {\n    opacity: 0.85;\n    transform: translateY(1px);\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var UserMenuDivider = styled.hr.withConfig({
    componentId: "sc-66d44f86-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
});
export var UserMenuItem = styled.button.withConfig({
    componentId: "sc-66d44f86-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, disabled = param.disabled;
    return theme.colors[disabled ? "textDisabled" : "textSubtle"];
}, function(param) {
    var disabled = param.disabled;
    return disabled ? "not-allowed" : "pointer";
}, function(param) {
    var theme = param.theme;
    return theme.colors.tertiary;
});
