import { Flex, Button, Text } from '@pancakeswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { useGasPriceManager } from 'state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'
import styled, { useTheme } from 'styled-components'

const StyledSoftText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

const GasSettings = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <StyledSoftText fontSize="12px">{t('Default Transaction Speed (GWEI)')}</StyledSoftText>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
          size="14px"
          color="primary"
        />
      </Flex>
      <Flex>
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          style={{
            fontSize: '10px',
            flexBasis: '33.333333%',
            color: gasPrice === GAS_PRICE_GWEI.default ? 'white' : theme.colors.softTextColor,
            backgroundColor:
              gasPrice === GAS_PRICE_GWEI.default ? theme.colors.upperMenuActive : theme.colors.inputPanelBackground,
            boxShadow: 'none',
          }}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </Button>
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          style={{
            fontSize: '10px',
            flexBasis: '33.333333%',
            color: gasPrice === GAS_PRICE_GWEI.fast ? 'white' : theme.colors.softTextColor,
            backgroundColor:
              gasPrice === GAS_PRICE_GWEI.fast ? theme.colors.upperMenuActive : theme.colors.inputPanelBackground,
            boxShadow: 'none',
          }}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </Button>
        <Button
          mr="4px"
          mt="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          style={{
            fontSize: '10px',
            flexBasis: '33.333333%',
            color: gasPrice === GAS_PRICE_GWEI.instant ? 'white' : theme.colors.softTextColor,
            backgroundColor:
              gasPrice === GAS_PRICE_GWEI.instant ? theme.colors.upperMenuActive : theme.colors.inputPanelBackground,
            boxShadow: 'none',
          }}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </Button>
      </Flex>
    </Flex>
  )
}

export default GasSettings
