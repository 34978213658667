import { useEffect, useState } from 'react'

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
  Text,
  Button,
  Link,
} from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import { useRouter } from 'next/router'
import ConnectWalletButton from 'components/ConnectWalletButton'

import useAuth from 'hooks/useAuth'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import { useTheme } from 'styled-components'
import { useCurrencyBalance } from '../../../state/wallet/hooks'
import { useCurrency } from 'hooks/Tokens'

import WalletModal, { WalletView } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletUserMenuItem from './WalletUserMenuItem'

import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapState,
  useSingleTokenSwapInfo,
} from '../../../state/swap/hooks'
import { Field, replaceSwapState, updateDerivedPairData, updatePairData } from '../../../state/swap/actions'

const UserMenu = (props) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const hasProfile = isInitialized && !!profile
  const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  const balance = useCurrencyBalance(account ?? undefined, useCurrency('BNB'))

  const usdtCurrency = useCurrency('0x55d398326f99059fF775485246999027B3197955')
  const { v2Trade: usdtInputTrade } = useDerivedSwapInfo(
    Field.INPUT,
    balance?.toSignificant(6),
    useCurrency('BNB'),
    usdtCurrency,
    'recipient',
    undefined,
    undefined,
  )
  const balanceUSD = usdtInputTrade?.outputAmount
    ? Math.round(parseFloat(usdtInputTrade?.outputAmount.toExact()) * 100) / 100
    : null

  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const [showExploreTooltip, setShowExploreTooltip] = useState(false)
  const [showExitTooltip, setShowExitTooltip] = useState(false)

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  const UserMenuItems = () => {
    const theme = useTheme()
    return (
      <div style={{ margin: 32 }}>
        <Flex style={{ color: theme.colors.userMenuText }}>
          <Flex style={{ flexGrow: 1 }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ borderRadius: '100%', marginRight: 12 }}
            >
              <g clipPath="url(#clip0_0_1)">
                <path
                  d="M13.3333 8.75H9.58333C9.1231 8.75 8.75 9.1231 8.75 9.58333V13.3333C8.75 13.7936 9.1231 14.1667 9.58333 14.1667H13.3333C13.7936 14.1667 14.1667 13.7936 14.1667 13.3333V9.58333C14.1667 9.1231 13.7936 8.75 13.3333 8.75Z"
                  stroke={theme.colors.userMenuText}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.0835 11.25H6.66683C6.44582 11.25 6.23385 11.1622 6.07757 11.006C5.92129 10.8497 5.8335 10.6377 5.8335 10.4167V6.66671C5.8335 6.44569 5.92129 6.23373 6.07757 6.07745C6.23385 5.92117 6.44582 5.83337 6.66683 5.83337H10.4168C10.6378 5.83337 10.8498 5.92117 11.0061 6.07745C11.1624 6.23373 11.2502 6.44569 11.2502 6.66671V7.08337"
                  stroke={theme.colors.userMenuText}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g clipPath="url(#clip1_0_1)">
                <path
                  d="M15.2153 28.6817L28.3218 8.57642L8.21653 -4.53003L-4.88992 15.5752L15.2153 28.6817Z"
                  fill="#016B8E"
                />
                <path
                  d="M-10.5972 28.9163L13.2485 31.6332L15.9654 7.78745L-7.88031 5.07058L-10.5972 28.9163Z"
                  fill="#F1F500"
                />
                <path
                  d="M-12.8573 -20.7394L-13.5693 3.25L10.4201 3.96199L11.1321 -20.0274L-12.8573 -20.7394Z"
                  fill="#F2D202"
                />
              </g>
              <defs>
                <clipPath id="clip0_0_1">
                  <rect width="10" height="10" fill="white" transform="translate(5 5)" />
                </clipPath>
                <clipPath id="clip1_0_1">
                  <rect width="24" height="24" fill="white" transform="translate(-2 -2)" />
                </clipPath>
              </defs>
            </svg>
            <div style={{ marginTop: 2, fontSize: 14 }}>
              {account && `${account.substring(0, 4)}...${account.substring(account.length - 4)} `}
            </div>
          </Flex>
          <Flex style={{ gap: 10 }}>
            {/* copy to clipboard button */}
            <div
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer', position: 'relative' }}
              onClick={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(account)
              }}
              onMouseOver={(e) => {
                e.persist()
                setShowCopyTooltip(true)
              }}
              onMouseLeave={(e) => {
                e.persist()
                setShowCopyTooltip(false)
              }}
              onFocus={(e) => {
                e.persist()
                setShowCopyTooltip(true)
              }}
              onKeyPress={(e) => e.persist()}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="7" fill={theme.colors.userMenuIconBackground} />
                <g clipPath="url(#clip0_7_7445)">
                  <path
                    d="M13.3333 8.75H9.58333C9.1231 8.75 8.75 9.1231 8.75 9.58333V13.3333C8.75 13.7936 9.1231 14.1667 9.58333 14.1667H13.3333C13.7936 14.1667 14.1667 13.7936 14.1667 13.3333V9.58333C14.1667 9.1231 13.7936 8.75 13.3333 8.75Z"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.0835 11.25H6.66683C6.44582 11.25 6.23385 11.1622 6.07757 11.006C5.92129 10.8497 5.8335 10.6377 5.8335 10.4167V6.66671C5.8335 6.44569 5.92129 6.23373 6.07757 6.07745C6.23385 5.92117 6.44582 5.83337 6.66683 5.83337H10.4168C10.6378 5.83337 10.8498 5.92117 11.0061 6.07745C11.1624 6.23373 11.2502 6.44569 11.2502 6.66671V7.08337"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7_7445">
                    <rect width="10" height="10" fill="white" transform="translate(5 5)" />
                  </clipPath>
                </defs>
              </svg>
              <span
                style={{ position: 'absolute', left: '-50%', top: '100%', display: showCopyTooltip ? 'block' : 'none' }}
              >
                Copy
              </span>
            </div>
            {/* bcscan button */}
            <div
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer', position: 'relative' }}
              onClick={(e) => {
                e.preventDefault()
                window.open(`https://bscscan.com/address/${account}`, '_blank')
              }}
              onMouseOver={(e) => {
                e.persist()
                setShowExploreTooltip(true)
              }}
              onMouseLeave={(e) => {
                e.persist()
                setShowExploreTooltip(false)
              }}
              onFocus={(e) => {
                e.persist()
                setShowExploreTooltip(true)
              }}
              onKeyPress={(e) => e.persist()}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="7" fill={theme.colors.userMenuIconBackground} />
                <g clipPath="url(#clip0_7_7449)">
                  <path
                    d="M12.5 10.4167V12.9167C12.5 13.1377 12.4122 13.3496 12.2559 13.5059C12.0996 13.6622 11.8877 13.75 11.6667 13.75H7.08333C6.86232 13.75 6.65036 13.6622 6.49408 13.5059C6.3378 13.3496 6.25 13.1377 6.25 12.9167V8.33333C6.25 8.11232 6.3378 7.90036 6.49408 7.74408C6.65036 7.5878 6.86232 7.5 7.08333 7.5H9.58333"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.25 6.25H13.75V8.75"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.1665 10.8333L13.7498 6.25"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7_7449">
                    <rect width="10" height="10" fill="white" transform="translate(5 5)" />
                  </clipPath>
                </defs>
              </svg>
              <span
                style={{
                  position: 'absolute',
                  left: '-50%',
                  top: '100%',
                  display: showExploreTooltip ? 'block' : 'none',
                }}
              >
                Explore
              </span>
            </div>
            {/* logout button */}
            <div
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer', position: 'relative' }}
              onClick={logout}
              onMouseOver={(e) => {
                e.persist()
                setShowExitTooltip(true)
              }}
              onMouseLeave={(e) => {
                e.persist()
                setShowExitTooltip(false)
              }}
              onFocus={(e) => {
                e.persist()
                setShowExitTooltip(true)
              }}
              onKeyPress={(e) => e.persist()}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="7" fill={theme.colors.userMenuIconBackground} />
                <g clipPath="url(#clip0_7_7454)">
                  <path
                    d="M12.6501 7.76672C13.1745 8.29122 13.5315 8.95941 13.6761 9.6868C13.8207 10.4142 13.7463 11.1681 13.4625 11.8533C13.1786 12.5384 12.698 13.124 12.0813 13.536C11.4647 13.948 10.7397 14.1679 9.99805 14.1679C9.25642 14.1679 8.53145 13.948 7.91479 13.536C7.29813 13.124 6.81748 12.5384 6.53362 11.8533C6.24975 11.1681 6.17542 10.4142 6.32001 9.6868C6.46461 8.95941 6.82164 8.29122 7.34597 7.76672"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 5.83337V10"
                    stroke={theme.colors.userMenuText}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7_7454">
                    <rect width="10" height="10" fill="white" transform="translate(5 5)" />
                  </clipPath>
                </defs>
              </svg>
              <span
                style={{ position: 'absolute', left: '-50%', top: '100%', display: showExitTooltip ? 'block' : 'none' }}
              >
                Logout
              </span>
            </div>
          </Flex>
        </Flex>
        <Flex flexDirection="column" marginTop="32px" marginBottom="32px" justifyItems="center">
          <Text width="100%" textAlign="center" fontSize="18px" color={theme.colors.userMenuText}>
            {balance?.toSignificant(6) ?? 'Loading...'} BNB
          </Text>
          <Text width="100%" textAlign="center" fontSize="12px" color={theme.colors.userMenuSubText}>
            {balanceUSD ?? 'Loading...'} USD
          </Text>
        </Flex>
        <hr color={theme.colors.hrColor} />
        <Flex flexDirection="column" marginTop="32px" color={theme.colors.userMenuText}>
          <Flex marginBottom="16px" style={{ cursor: 'pointer' }} onClick={onPresentTransactionModal}>
            <Text style={{ width: '100%', color: theme.colors.userMenuText, flexGrow: 1 }}>Transactions</Text>
            <svg
              style={{ marginTop: 6 }}
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 9L5 5L1 1" stroke={theme.colors.userMenuText} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Flex>
          <Flex style={{ cursor: 'pointer' }} onClick={props.toggleTheme}>
            <Text style={{ width: '100%', color: theme.colors.userMenuText, flexGrow: 1 }}>Dark Theme</Text>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.9999 7.52667C12.8951 8.66147 12.4692 9.74294 11.7721 10.6445C11.075 11.5461 10.1356 12.2305 9.06374 12.6177C7.99188 13.0049 6.83192 13.0787 5.7196 12.8307C4.60728 12.5827 3.5886 12.023 2.78275 11.2172C1.97691 10.4113 1.41723 9.39265 1.16921 8.28033C0.921185 7.16801 0.995078 6.00806 1.38224 4.9362C1.7694 3.86434 2.45382 2.92491 3.35541 2.22784C4.257 1.53076 5.33847 1.10487 6.47327 1C5.80888 1.89884 5.48917 3.0063 5.57229 4.12094C5.65541 5.23559 6.13584 6.28337 6.9262 7.07373C7.71656 7.86409 8.76435 8.34452 9.87899 8.42765C10.9936 8.51077 12.1011 8.19106 12.9999 7.52667V7.52667Z"
                stroke={theme.colors.hardTextColor}
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Flex>
        </Flex>
        {/* <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" style={{ color: theme.colors.failure }} justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem> */}
      </div>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen }) => {
          if (isOpen) {
            return <UserMenuItems />
          }
          setShowCopyTooltip(false)
          setShowExploreTooltip(false)
          setShowExitTooltip(false)
          return null
        }}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => {
          if (isOpen) {
            return <UserMenuItems />
          }
          setShowCopyTooltip(false)
          setShowExploreTooltip(false)
          setShowExitTooltip(false)
          return null
        }}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton
      scale="sm"
      width={154}
      height={34}
      style={{ borderRadius: 100, fontSize: 14, boxShadow: 'none', border: 'none' }}
    >
      <Box display={['none', , , 'block']} style={{ fontFamily: 'DM Sans' }}>
        <Trans>Connect Wallet</Trans>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
