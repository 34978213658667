import { Box, Button, Flex, Text } from '@pancakeswap/uikit'
import { useAppDispatch } from 'state'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { clearAllTransactions } from 'state/transactions/actions'
import orderBy from 'lodash/orderBy'
import TransactionRow from './TransactionRow'
import styled from 'styled-components'

const StyledSoftText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

const StyledHardText = styled(Text)`
  color: ${({ theme }) => theme.colors.hardTextColor};
`

const WalletTransactions: React.FC = () => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const allTransactions = useAllTransactions()
  const sortedTransactions = orderBy(Object.values(allTransactions).filter(isTransactionRecent), 'addedTime', 'desc')

  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }

  return (
    <Box style={{ padding: '20px 0px 0px 0px' }} minHeight="120px">
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        {sortedTransactions.length > 0 && (
          <>
            <StyledHardText>{t('Recent Transactions')}</StyledHardText>
            <Button scale="sm" onClick={handleClearAll} style={{ fontSize: 10 }}>
              {t('Clear all')}
            </Button>
          </>
        )}
      </Flex>
      {sortedTransactions.length > 0 ? (
        <div style={{ marginTop: 36 }}>
          {sortedTransactions.map((txn) => (
            <TransactionRow key={txn.hash} txn={txn} />
          ))}
        </div>
      ) : (
        <StyledSoftText textAlign="center">{t('No recent transactions')}</StyledSoftText>
      )}
    </Box>
  )
}

export default WalletTransactions
